import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const fishing = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'FISHING/ | HB Privileged',
      title: 'FISHING/',
      subtitle: 'TOUR OVERVIEW',
      text: "When it's time to talk about fishing, our friends of Mike's are the experts. Not only do they have the most comfortable and wide vessels in the industry, but they have the most experienced crews. Winners of several Championships, the captain and crew of their boats will make you feel like an expert while enjoying the best services using the best fishing gear. Poles, bait, licenses water and ice are included, but bring anything you wish to eat or drink on board at no extra cost. Whether we go for the mythical Black Marlin of hundreds of pounds of weight, or if we take home the colorful Rooster Fish, fishing in Vallarta es definitely something to remember. Don ́t miss out on this opportunity and reserve your private fishing tour with Mike's right now.",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'PESCA | HB Privileged',
      title: 'PESCA',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Cuando de pesca se trata, nuestros amigos de Mike ́s son los expertos. No solo tienen las embarcaciones más amplias y cómodas de la industria, sino que además tienen las tripulaciones más experimentadas. Ganadores de varios torneos, el capitán y la tripulación de sus embarcaciones te harán sentir como un experto mientras recibes el mejor servicio, utlizando el mejor equipo. Las cañas, la carnada, la licencia,el agua y el hielo están incluídos, pero trae todo lo que desees consumir en tu expedición sin ningún costo extra. Ya sea que vayamos por el mítico Marlin Negro de cientos de kilos de peso o que nos llevemos al colorido Pez Gallo, el ir a pescar en Vallarta es definitivamente algo para recordar. No pierdas esta oportunidad y reserva ya tu pesca privada con Mike ́s.',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/fishing1.jpg`,
    `${BASE_URL}/tours/fishing2.jpg`,
    `${BASE_URL}/tours/fishing3.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/fishing.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default fishing;